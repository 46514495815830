import React from "react";
import App from "../App";

import {
  Hub,
  Proposals,
  News,
  Archive,
  About,
  Contact,
  Flex,
  ServicesPage,
  NewsDetail,
  Recruitment,
  BackgroundCheck,
  VettingServices,
  PageNotFound,
  Training
} from "../screens";



const Router = [{
  path: "/",
  element: <App><Hub /></App>,
},
{
  path: "/services",
  element: <App><ServicesPage /></App>,
},
{
  path: "/flex",
  element: <App><Flex /></App>,
  },
  {
    path: "/vetting-services",
    element: <App><VettingServices /></App>,
  },
{
  path: "/news",
  element: <App><News /></App>,
},
{
  path: "/news-details/:title/:id",
  pathnameBase: "/news-details",
  element: <App><NewsDetail /></App>,
},
  {
    path: "/news/:title/:id",
    pathnameBase: "/news",
    element: <App><NewsDetail /></App>,
  },
{
  path: "/contact",
  element: <App><Contact /></App>,
},
{
  path: "/about",
  element: <App><About /></App>,
},
{
  path: "/recruitment",
  element: <App><Recruitment /></App>,
},
{
  path: "/background-check",
  element: <App><BackgroundCheck /></App>,
},
{
  path: "/training",
  element: <App><Training /></App>,
}
];

export default Router;
